import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"mt-2",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"pa-4",attrs:{"min-width":"400","color":"white"}},[_c(VRow,{attrs:{"justify":"center"}},[_c('h2',{staticClass:"primary--text justify-center"},[_vm._v("Se connecter")])]),_c(VForm,{ref:"loginForm"},[_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Nom d'utilisateur"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"rules":[_vm.notNullNorEmpty],"label":"Mot de passe","type":_vm.showPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VIcon,{on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_vm._v(_vm._s(_vm.showPassword ? "mdi-eye" : 'mdi-eye-outline'))])]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VRow,{staticClass:"mt-2 mr-2",attrs:{"justify":"end"}},[_c('router-link',{attrs:{"to":"/forgotpassword"}},[_vm._v("Mot de passe oublié")])],1),_c(VCheckbox,{attrs:{"label":"Se souvenir de moi"},model:{value:(_vm.rememberMe),callback:function ($$v) {_vm.rememberMe=$$v},expression:"rememberMe"}}),_c(VRow,{staticClass:"mb-4",attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.login}},[_vm._v("Se connecter")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }