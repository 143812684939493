<template>
  <v-container>
    <v-row class="mt-2" justify="center">
      <v-col cols="6">

        <v-card min-width="400" class="pa-4" color="white">
          <v-row justify="center">
            <h2 class="primary--text justify-center">Se connecter</h2>
          </v-row>
          <v-form ref="loginForm">
            <v-text-field :rules="[notNullNorEmpty]" label="Nom d'utilisateur" v-model="username"></v-text-field>
            <v-text-field  :rules="[notNullNorEmpty]" label="Mot de passe" :type="showPassword ? 'text' : 'password'" v-model="password">
              <template v-slot:append-outer>
                <v-icon @click="showPassword = !showPassword">{{ showPassword ? "mdi-eye" : 'mdi-eye-outline' }}</v-icon>
              </template>
            </v-text-field>
            <v-row class="mt-2 mr-2" justify="end">
              <router-link to="/forgotpassword">Mot de passe oublié</router-link>
            </v-row>
            <v-checkbox v-model="rememberMe" label="Se souvenir de moi"></v-checkbox>
            <v-row justify="center" class="mb-4">
              <v-btn @click="login" color="primary" class="white--text">Se connecter</v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";
import {AUTHENTIFICATION_STATUS} from "@/constants/AuthentificationStatus";

export default {
  name: "Login",
  mixins: [ValidationRulesMixin],
  data() {
    return {
      username: "",
      password: "",
      rememberMe: false,
      showPassword: false,
    }
  },
  methods: {
    async login() {
      if(this.$refs.loginForm.validate()){
        await this.$store.dispatch("auth/login", {username: this.username, password: this.password, rememberMe:this.rememberMe});
        let status = this.$store.getters["auth/getStatus"];
        if (status === AUTHENTIFICATION_STATUS.ERROR) {
          await this.$store.dispatch("annonce/annonceError", "Une erreur est survenue.");
        } else if(status === AUTHENTIFICATION_STATUS.REFUSED) {
          await this.$store.dispatch("annonce/annonceError", "Nom d'utilisateur ou mot de passe incorrect.");
        }
        else if(status === AUTHENTIFICATION_STATUS.LOGGED_IN) {
          await this.$router.push("/");
        } else {
          await this.$store.dispatch("annonce/annonceError", "Une erreur inconnu est survenue.");
        }
      }
    }
  },
}
</script>

<style scoped>

</style>